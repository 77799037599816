.ant-collapse-header {
    font-size: 50px;
    font-weight: bold;
}

.ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #d9d9d9;
    min-height: 100px;
    padding: 20px 0;
    font-size: 60px;
}