/* #ac0300; /* red */
/* cf8080 light red */

/* #3a6590; /* blue */
/* 829bb6 light blue */

/* #1D1311; /* black */

.strikethrough {
  text-decoration: line-through;
}
.logo {
  min-width: 120px;
  height: 100%;
  /* background: rgba(255,255,255,.2); */
  /* margin: 16px 24px 16px 0; */
  float: left;
  background: url('./images/flagblack.svg') no-repeat;
  background-size: cover;
  background-position: center center;
}

/* Background Colors */
.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-layout-header {
  background: #1D1311;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item, .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  border-color: #1D1311;
}

/* Navigation bar selected */
.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #3a6590; /* blue */
}

.ant-layout-content .ant-row {
/* .ant-layout-content .ant-row, .ant-layout-content header { */
  padding: 100px 0px;
}

.ant-layout-content {
  text-align: center;
}

/* ye4us Header */
.ant-layout-content header {
  height: 100%;
  min-height: 100vh;
  background-image: url('./images/lifeofpablo.jpg');
  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ant-layout-content header h1 {
  color: white;
  text-align: center;
  /* font-size: 400px; */
  font-size: 20vw;
}
@media only screen and (max-width: 600px) {
  .ant-layout-content header h1 {
    font-size: 30vw;
  }
}

.ant-layout-content .quote {
  font-style: italic;
}

.ant-layout-content h2 {
  font-size: 4vw;
}
@media only screen and (max-width: 600px) {
  .ant-layout-content h2 {
      font-size: 8vw;
  }
}

.ant-layout-content h3 {
  font-size: 1.5vw;
}
@media only screen and (max-width: 600px) {
  .ant-layout-content h3 {
    font-size: 5.5vw;
  }
}

/* Dark Theme */
.ant-layout-content .row-dark-theme {
  background-color: #1D1311; /* black */
}
.ant-layout-content .row-dark-theme h2 {
  color: white;
}

/* Red Theme */
.ant-layout-content .row-red-theme {
  background-color: #ac0300; /* red */
}
.ant-layout-content .row-red-theme h2 {
  color: white;
}

/* Blue Theme */
.ant-layout-content .row-blue-theme {
  background-color: #3a6590; /* blue */
}
.ant-layout-content .row-blue-theme h2 {
  color: white;
}

/* Issues Nav container */
.ant-layout-content .ant-tabs-nav-container {
  font-size: 2.4vw;
}

@media only screen and (max-width: 600px) {
  .ant-layout-content .ant-tabs-nav-container {
      font-size: 6vw;
  }
}

/* ye4us title */
.ant-layout-content h1 span:first-child {
  color: #ac0300; /* red */
}
.ant-layout-content h1 span:last-child {
  color: #3a6590; /* blue */
}
.ant-layout-header {
    padding: 0 20px;
  }

.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a {
  font-size: 2.5vh;
}

.ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
  font-size: 2.5vh;
}
.videoContainer {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoContainer iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


.ant-carousel .slick-slide {
  text-align: center;
  width: 100%;
  line-height: 160px;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: black;
}

.ant-carousel .slick-dots li button {
    background: #1D1311; /* black */
    width: 50px;
    height: 20px;
    margin: 2px;
}
.ant-layout-footer {
    background: #1D1311; /* black */
    padding: 24px 50px;
    color: white;
    font-size: 14px;
}

.ant-layout-footer s {
  text-decoration: line-through;
}